import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import Link from "gatsby-link";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";
import { realisationsData } from "../data/realisations";

const Realisations = ({ data }) => {
  const imagesSource = (item) => {
    return [
      {
        ...data[`${item.imagesMobile[0]}`].childImageSharp.fluid,
        media: `(max-width: 767px)`,
      },
      {
        ...data[`${item.images[0]}`].childImageSharp.fluid,
        media: `(min-width: 768px)`,
      },
    ];
  };

  return (
    <Layout>
      <SEO
        title="Nos réalisations de sites internet à Nantes"
        description="Découvrez les sites et applications web que nous avons développé. Ils ont adoré !"
      />
      <Banner
        title="Nos réalisations"
        baseline="Ils ont adoré (et nous aussi)"
        imageBanner={data.imageBanner.childImageSharp.fluid}
      />
      <section
        id="portfolio-masonry"
        className="portfolio portfolio-vintage portfolio-3col portfolio-animation"
      >
        {/* <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 portfolio-filter">
            <ul className="list-inline text-center">
              <li>
                <a className="active-filter" href="#" data-filter="*">
                  All<span>16</span>
                </a>
              </li>
              <li>
                <a href="#" data-filter=".filter-Branding">
                  Branding<span>3</span>
                </a>
              </li>
              <li>
                <a href="#" data-filter=".filter-Packaging">
                  Packaging<span>5</span>
                </a>
              </li>
              <li>
                <a href="#" data-filter=".filter-Print">
                  Print<span>0</span>
                </a>
              </li>
              <li>
                <a href="#" data-filter=".filter-Web">
                  Web Development<span>2</span>
                </a>
              </li>
              <li>
                <a href="#" data-filter=".filter-Interior">
                  Interior design<span>6</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
        <div className="container">
          <div id="portfolio-all" className="row">
            {realisationsData.map((item, index) => (
              <div key={index} className={`${item.col} portfolio-item`}>
                <div className="portfolio-item-box">
                  <Link
                    className="portfolio-img"
                    to={item.url}
                    title={item.description}
                  >
                    <Img
                      fluid={
                        item.imagesMobile
                          ? imagesSource(item)
                          : data[`${item.images[0]}`].childImageSharp.fluid
                      }
                      alt={item.client}
                    />
                    <div className="portfolio-hover">
                      <div className="portfolio-hover-content">
                        <div className="portfolio-meta">
                          <div className="portfolio-title">
                            <h4>{item.client}</h4>
                          </div>
                          <div className="portfolio-cat">
                            <span>
                              {item.techno.map((tech, index) => (
                                <span key={index}>{tech}</span>
                              ))}
                            </span>
                          </div>
                        </div>
                        <div className="portfolio-action">
                          <div className="portfolio-zoom-border">
                            <i className="far fa-plus" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 portfolio-more">
            <a href="#">Voir plus de projets</a>
          </div>
        </div>
      </div> */}
      </section>
    </Layout>
  );
};

export default Realisations;

export const pageQuery = graphql`
  query {
    imageBanner: file(
      relativePath: { eq: "background/bannerRealisationsOriginal.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageWorkUp: file(relativePath: { eq: "portfolio/workup.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBattlerite: file(relativePath: { eq: "portfolio/battlerite.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imagePortfolio: file(relativePath: { eq: "portfolio/portfolio.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageRenezsens: file(relativePath: { eq: "portfolio/renezsens-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHappe: file(relativePath: { eq: "portfolio/happ-e.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHappeMobile: file(
      relativePath: { eq: "portfolio/happ-e-mobile.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageSlap: file(relativePath: { eq: "portfolio/slap-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCollectif: file(relativePath: { eq: "portfolio/collectif-1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageKevCoachingPro: file(
      relativePath: { eq: "portfolio/kevin-home-crop.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
